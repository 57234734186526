import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import ViewButton from "../../components/viewButton/ViewButton";
import { updateActiveStatus } from "../../api/auth"; // Assuming the API call function is here
import { useDispatch } from "react-redux"; // Use redux to dispatch actions

const columns = [
  { id: "DoctorID", label: "Doctor ID", minWidth: 120 },
  { id: "creationDate", label: "Creation Date", minWidth: 120 },
  { id: "DoctorName", label: "Doctor Name", minWidth: 150 },
  {
    id: "PrimarySpecialization",
    label: "Primary Specialization",
    minWidth: 170,
  },
  {
    id: "SecondarySpecialization",
    label: "Secondary Specialization",
    minWidth: 200,
    align: "center",
  },
  {
    id: "Consultations",
    label: "Consultations",
    minWidth: 150,
    align: "center",
  },
  { id: "AllPatients", label: "All Patients", minWidth: 150, align: "center" },
  { id: "Rating", label: "Rating", minWidth: 150, align: "center" },
  {
    id: "AccStatus",
    label: "Acc Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

export default function DoctorsTable({ doctors }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle update of the doctor's status (active/inactive)
  const handleUpdate = async (doctorId, newStatus) => {
    console.log(doctorId, newStatus, "new status");
    const data = {
      doctorId,
      account_status: newStatus,
    };
    console.log(data, "data");

    try {
      await dispatch(updateActiveStatus(data));
      // Optionally, show success feedback (toast or alert)
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  // Handle view button click for navigating to the doctor detail page
  const handleViewButtonClick = (doctorID) => {
    navigate(`/doctors/update/${doctorID}`);
  };

  return (
    <div className="max-w-[98vw] sm:max-w-full">
      <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{ padding: "8px", borderBottom: "2px solid #031E3E" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {doctors
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((doctor) => {
                const isActive = doctor.account_status === "active"; // Check if doctor is active
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={doctor._id}
                  >
                    <TableCell>{doctor.doctorId || doctor._id}</TableCell>
                    <TableCell>
                      {new Date(doctor.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{doctor.name}</TableCell>
                    <TableCell>{doctor.primary_specialization}</TableCell>
                    <TableCell align="center">
                      {doctor.secondary_specialization}
                    </TableCell>
                    <TableCell align="center">
                      {doctor.consultations || 0}
                    </TableCell>
                    <TableCell align="center">
                      {doctor.all_patients || 0}
                    </TableCell>
                    <TableCell align="center">
                      {doctor.rating || "N/A"}
                    </TableCell>
                    {/* ToggleSwitch for Account Status */}
                    <TableCell align="center">
                      <ToggleSwitch
                        checked={doctor.account_status === "active"} // Check if the doctor is active, then the switch is ON
                        onChange={() =>
                          handleUpdate(
                            doctor._id,
                            doctor.account_status === "active"
                              ? "inactive"
                              : "active"
                          )
                        }
                      />
                    </TableCell>

                    {/* View button */}

                    <TableCell align="center">
                      <ViewButton
                        onClick={() => handleViewButtonClick(doctor._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={doctors.length} // Total count of doctors
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
