// import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
// import React, { useState, useRef, useEffect } from "react";
// import MainSection from "../../components/mainSection/MainSection";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Sectionheader from "../../components/sectionHeader/SectionHeader";
// import { useNavigate } from "react-router-dom";
// import { addDoctor } from "../../api/doctor";
// import { useDispatch, useSelector } from "react-redux";
// import InputAdornment from "@mui/material/InputAdornment";
// import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
// import { Button } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import { fetchAllDoctors, adminSelector } from "../../api/auth";

// const CreateHealthAdmin = () => {
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [safetyInfo, setSafetyInfo] = useState([""]);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [profile, setProfile] = useState({ profileImage: null });
//   const [passwordError, setPasswordError] = useState("");
//   const [previewImage, setPreviewImage] = useState(null);
//   const fileInputRef = useRef(null);
//   const [formData, setFormData] = useState({
//     admin_name: "",
//     email: "",
//     password: "",
//     confirm_password: "",
//   });
//   const [assignDoctors, setAssignDoctors] = useState([{}]);

//   const { adminToken, doctors } = useSelector(adminSelector);
//   console.log(adminToken, "Admin");

//   useEffect(() => {
//     if (adminToken) {
//       dispatch(fetchAllDoctors(adminToken));
//     }
//   }, [adminToken, dispatch]);

//   const handleProfile = (e) => {
//     const { files } = e.target;
//     const file = files[0];

//     // Check if a file was selected
//     if (file) {
//       // Update state with the selected file and set the preview image
//       setProfile((prev) => ({ ...prev, profileImage: file }));
//       setPreviewImage(URL.createObjectURL(file));
//     } else {
//       // Optional: handle case when no file is selected (if needed)
//       console.warn("No file selected");
//     }
//   };

//   const handleRemoveImage = () => {
//     setFormData((prev) => ({ ...prev, profileImage: null }));
//     setPreviewImage(null);
//     if (fileInputRef.current) {
//       fileInputRef.current.value = "";
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     if (name === "password") {
//       // Check password match only when typing in the password field
//       if (value !== formData.confirm_password) {
//         setPasswordError("Passwords do not match.");
//       } else {
//         setPasswordError("");
//       }
//     }

//     if (name === "confirm_password") {
//       // Check password match only when typing in the confirm password field
//       if (formData.password !== value) {
//         setPasswordError("Passwords do not match.");
//       } else {
//         setPasswordError("");
//       }
//     }
//   };
//   // Toggle password visibility
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   // Toggle confirm password visibility
//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   const option1 = [
//     { value: "Cardiology", label: "Cardiology" },
//     { value: "Dermatology", label: "Dermatology" },
//     { value: "Neurology", label: "Neurology" },
//     { value: "Pediatrics", label: "Pediatrics" },
//     { value: "Orthopedics", label: "Orthopedics" },
//     { value: "Oncology", label: "Oncology" },
//     { value: "Psychiatry", label: "Psychiatry" },
//     { value: "General Surgery", label: "General Surgery" },
//     { value: "Gynecology", label: "Gynecology" },
//     { value: "Ophthalmology", label: "Ophthalmology" },
//   ];

//   const option2 = [
//     { value: "Sports Medicine", label: "Sports Medicine" },
//     { value: "Geriatrics", label: "Geriatrics" },
//     { value: "Immunology", label: "Immunology" },
//     { value: "Rheumatology", label: "Rheumatology" },
//     { value: "Endocrinology", label: "Endocrinology" },
//     { value: "Palliative Care", label: "Palliative Care" },
//     { value: "Infectious Diseases", label: "Infectious Diseases" },
//     { value: "Allergy and Asthma", label: "Allergy and Asthma" },
//     { value: "Pain Management", label: "Pain Management" },
//     {
//       value: "Reproductive Endocrinology",
//       label: "Reproductive Endocrinology",
//     },
//   ];

//   return (
//     <div className=" font-semibold">
//       <Sidebar />
//       {/* <Sidebar onNavigate={handleNavigation} /> */}

//       <MainSection>
//         <Sectionheader title="Create New Health Admin"></Sectionheader>
//         <div className=" bg-white">
//           <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
//             {/* Left Section */}
//             <div className="space-y-6 sm:border-r pr-10">
//               <h2 className="text-lg text-red-800">Basic Details</h2>
//               <div className="flex items-center space-x-4">
//                 <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
//                   {previewImage ? (
//                     <img
//                       src={previewImage}
//                       alt="Profile Preview"
//                       className="w-full h-full object-cover"
//                     />
//                   ) : (
//                     <AiOutlineUser className="h-1/2 w-auto text-gray-400" />
//                   )}
//                   {previewImage && (
//                     <button
//                       className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
//                       title="Remove Image"
//                     >
//                       <AiOutlineClose className="text-gray-500" />
//                     </button>
//                   )}
//                 </div>
//                 <div className="flex flex-col items-start">
//                   <input
//                     type="file"
//                     id="fileUpload"
//                     className="hidden"
//                     accept="image/*"
//                   />
//                   <label
//                     htmlFor="fileUpload"
//                     className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300"
//                   >
//                     Upload Profile Picture
//                   </label>
//                 </div>
//               </div>
//               <div className="space-y-4">
//                 <div>
//                   <label
//                     htmlFor="name"
//                     className="block mb-2 text-sm text-gray-900"
//                   >
//                     Health Admin Name
//                   </label>
//                   <input
//                     type="text"
//                     id="name"
//                     name="name"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
//                     placeholder="Enter doctor name"
//                     required
//                   />
//                 </div>

//                 <div>
//                   <label
//                     htmlFor="email"
//                     className="block mb-2 text-sm text-gray-900"
//                   >
//                     Email
//                   </label>
//                   <input
//                     type="email"
//                     id="email"
//                     name="email"
//                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
//                     placeholder="Enter email"
//                     required
//                   />
//                 </div>
//                 <div>
//                   <label
//                     htmlFor="password"
//                     className="block mb-2 text-sm text-gray-900"
//                   >
//                     Password
//                   </label>
//                   <div className="relative">
//                     <input
//                       id="password"
//                       name="password"
//                       type={showPassword ? "text" : "password"}
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
//                       placeholder="Enter password"
//                     />
//                     <div
//                       className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
//                       onClick={togglePasswordVisibility}
//                     >
//                       {showPassword ? <FaEyeSlash /> : <FaEye />}
//                     </div>
//                   </div>
//                 </div>
//                 <div>
//                   <label
//                     htmlFor="confirm_password"
//                     className="block mb-2 text-sm text-gray-900"
//                   >
//                     Confirm Password
//                   </label>
//                   <div className="relative">
//                     <input
//                       type={showConfirmPassword ? "text" : "password"}
//                       id="confirm_password"
//                       name="confirm_password"
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
//                       placeholder="Enter password"
//                       required
//                     />
//                     <div
//                       type="button"
//                       className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
//                       onClick={toggleConfirmPasswordVisibility}
//                     >
//                       {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="space-y-6">
//               <h2 className="text-lg text-red-800">Analytics Information</h2>

//               <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                 <div>
//                   <label for="" class="block mb-2 text-sm  text-gray-900 ">
//                     Doctor Assigned
//                   </label>
//                   <input
//                     type="number"
//                     id=""
//                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
//                     placeholder="Doctor Assigned"
//                     required
//                   />
//                 </div>
//                 <div>
//                   <label
//                     for="first_name"
//                     class="block mb-2 text-sm  text-gray-900 "
//                   >
//                     Patients Handled
//                   </label>
//                   <input
//                     type="number"
//                     id=""
//                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
//                     placeholder="Patients Handled"
//                     required
//                   />
//                 </div>
//               </div>

//               <hr className="mb-4"></hr>

//               <h2 className="text-lg text-red-800">Health Admin Settings</h2>

//               <div className="space-y-4">
//                 <div className="mb-2"></div>
//                 <div className="mb-2"></div>
//               </div>

//               <div>
//                 <label className="block text-sm font-medium mb-2">
//                   Assign Doctors
//                 </label>
//                 {safetyInfo.map((info, index) => (
//                   <div key={index} className="flex items-center gap-2 mb-2">
//                     <select
//                       value={info}
//                       onChange={(e) => {
//                         const newSafetyInfo = [...safetyInfo];
//                         newSafetyInfo[index] = e.target.value;
//                         setSafetyInfo(newSafetyInfo);
//                       }}
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
//                       placeholder="Select doctors to assign"
//                     >
//                       <option className="text-gray-500">
//                         Select doctors to assign
//                       </option>
//                       <option>Option1</option>
//                       <option>Option2</option>
//                       <option>Optio31</option>
//                     </select>
//                     {safetyInfo.length > 1 && (
//                       <AiOutlineClose className="text-black cursor-pointer" />
//                     )}
//                   </div>
//                 ))}
//                 <Button className="flex items-center hover:!text-[#681312] hover:!border-[#681312]">
//                   <PlusOutlined /> Add
//                 </Button>
//               </div>
//             </div>
//           </div>
//           <hr className="mt-6 mb-6"></hr>
//           <button
//             className="px-4 py-2 bg-[#681312] text-white rounded-md"
//             // onClick={handleSubmit}
//           >
//             Save Details
//           </button>
//         </div>
//       </MainSection>
//     </div>
//   );
// };

// export default CreateHealthAdmin;

import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
import React, { useState, useRef, useEffect } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { fetchAllDoctors, adminSelector, addhealthAdmin } from "../../api/auth";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";

const { Option } = Select;

const CreateHealthAdmin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [profile, setProfile] = useState({ profileImage: null });

  const [formData, setFormData] = useState({
    admin_name: "",
    email: "",
    password: "",
    confirm_password: "",
    doctorassign: [{ doctorId: null }], // Always start with one default doctor
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminToken, doctors } = useSelector(adminSelector);

  // Fetch all doctors when the component is mounted
  useEffect(() => {
    if (adminToken) {
      dispatch(fetchAllDoctors());
    }
  }, [adminToken, dispatch]);

  // Handle profile image upload
  // const handleProfile = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setPreviewImage(URL.createObjectURL(file));
  //     setFormData((prev) => ({ ...prev, profileImage: file }));
  //   }
  // };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setFormData((prev) => ({ ...prev, profileImage: null }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setProfile((prev) => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };
  // Handle input changes for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // Handle doctor selection and store doctorId in the doctorassign array
  const handleDoctorSelection = (value, index) => {
    const updatedDoctors = [...formData.doctorassign];
    updatedDoctors[index].doctorId = value;
    setFormData((prev) => ({
      ...prev,
      doctorassign: updatedDoctors,
    }));
  };

  // Remove a doctor from the doctorassign array
  const removeDoctor = (index) => {
    const updatedDoctorAssign = formData.doctorassign.filter(
      (_, i) => i !== index
    );
    setFormData((prev) => ({
      ...prev,
      doctorassign: updatedDoctorAssign.length
        ? updatedDoctorAssign
        : [{ doctorId: null }], // Ensure there's always one input
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password === formData.confirm_password) {
      const doctorData = {
        ...formData,
        profileImage: profile.profileImage,
      };
      dispatch(addhealthAdmin(doctorData));
      setTimeout(() => {
        navigate(-1); // Navigate back after the delay
      }, 3000);
    }
  };

  return (
    <div className="font-semibold">
      <Sidebar />
      <MainSection>
        <Sectionheader title="Create New Health Admin" />
        <div className="bg-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {/* Left Section */}
            <div className="space-y-6 sm:border-r pr-10">
              <h2 className="text-lg text-red-800">Basic Details</h2>
              <div className="flex items-center space-x-4">
                <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <AiOutlineUser className="h-1/2 w-auto text-gray-400" />
                  )}
                  {previewImage && (
                    <button
                      className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
                      title="Remove Image"
                      onClick={handleRemoveImage}
                    >
                      <AiOutlineClose className="text-gray-500" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <input
                    type="file"
                    id="fileUpload"
                    className="hidden"
                    accept="image/*"
                    onChange={handleProfile}
                    ref={fileInputRef}
                  />
                  <label
                    htmlFor="fileUpload"
                    className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100"
                  >
                    Upload Profile Picture
                  </label>
                </div>
              </div>

              {/* Admin Name and Email Fields */}
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="admin_name"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Health Admin Name
                  </label>
                  <input
                    type="text"
                    id="admin_name"
                    name="admin_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Enter admin name"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Enter email"
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Password Fields */}
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
                      placeholder="Enter password"
                      onChange={handleChange}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      name="confirm_password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
                      placeholder="Confirm password"
                      onChange={handleChange}
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Analytics Information Section */}
            <div className="space-y-6">
              <h2 className="text-lg text-red-800">Analytics Information</h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="doctor_assigned"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Doctor Assigned
                  </label>
                  <input
                    type="number"
                    id="doctor_assigned"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Doctor Assigned"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="patients_handled"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Patients Handled
                  </label>
                  <input
                    type="number"
                    id="patients_handled"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Patients Handled"
                    required
                  />
                </div>
              </div>

              <hr className="mb-4"></hr>

              {/* Right Section - Assign Doctors */}
              <div className="space-y-6">
                <h2 className="text-lg text-red-800">Assign Doctors</h2>
                {formData.doctorassign.map((doctor, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <Select
                      placeholder="Select Doctor"
                      style={{ width: "100%" }}
                      onChange={(value) => handleDoctorSelection(value, index)}
                      value={doctor.doctorId}
                    >
                      {doctors?.map((doc) => (
                        <Option key={doc._id} value={doc._id}>
                          {doc.name}
                        </Option>
                      ))}
                    </Select>
                    {formData.doctorassign.length > 1 && (
                      <AiOutlineClose
                        className="text-black cursor-pointer"
                        onClick={() => removeDoctor(index)}
                      />
                    )}
                  </div>
                ))}
                <Button
                  className="flex items-center hover:!text-[#681312] hover:!border-[#681312]"
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      doctorassign: [...prev.doctorassign, { doctorId: null }],
                    }))
                  }
                >
                  <PlusOutlined /> Add Doctor
                </Button>
              </div>
            </div>
          </div>

          <hr className="mt-6 mb-6"></hr>
          <button
            className="px-4 py-2 bg-[#681312] text-white rounded-md"
            onClick={handleSubmit}
          >
            Save Details
          </button>
        </div>
      </MainSection>
    </div>
  );
};

export default CreateHealthAdmin;
