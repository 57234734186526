import { BiImage } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addDoctor } from "../../../api/doctor";
import { useDispatch, useSelector } from "react-redux";
import DropZone from "../../../components/dropzone/Dropzone";

const BasicDetails = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.doctor);
  const [profile, setProfile] = useState({ profileImage: null });
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    bio: "",
    email: "",
    password: "",
    primary_specialization: "",
    secondary_specialization: "",
  });

  const option1 = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Orthopedics", label: "Orthopedics" },
    { value: "Oncology", label: "Oncology" },
    { value: "Psychiatry", label: "Psychiatry" },
    { value: "General Surgery", label: "General Surgery" },
    { value: "Gynecology", label: "Gynecology" },
    { value: "Ophthalmology", label: "Ophthalmology" },
  ];

  const option2 = [
    { value: "Sports Medicine", label: "Sports Medicine" },
    { value: "Geriatrics", label: "Geriatrics" },
    { value: "Immunology", label: "Immunology" },
    { value: "Rheumatology", label: "Rheumatology" },
    { value: "Endocrinology", label: "Endocrinology" },
    { value: "Palliative Care", label: "Palliative Care" },
    { value: "Infectious Diseases", label: "Infectious Diseases" },
    { value: "Allergy and Asthma", label: "Allergy and Asthma" },
    { value: "Pain Management", label: "Pain Management" },
    {
      value: "Reproductive Endocrinology",
      label: "Reproductive Endocrinology",
    },
  ];

  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setProfile((prev) => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRemoveImage = () => {
    setFormData((prev) => ({ ...prev, profileImage: null }));
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const doctorData = {
      ...formData, // Spread formData properties
      profileImage: profile.profileImage, // Add the profile image
    };

    dispatch(addDoctor(doctorData));
  };

  useEffect(() => {
    if (success) {
      setFormData({
        name: "",
        primary_specialization: "",
        secondary_specialization: "",
        bio: "",
        email: "",
        password: "",
        profileImage: null,
      });
      setPreviewImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      // Reset additional state if needed, e.g., success state
    }
  }, [success]);
  return (
    <div>
      <div className="bg-white">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {/* Left Section */}
          <div className="space-y-6 sm:border-r pr-10">
            <div className="flex items-center space-x-4">
              <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Profile Preview"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <BiImage className="h-1/2 w-auto text-gray-400" />
                )}
                {previewImage && (
                  <button
                    onClick={handleRemoveImage}
                    className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
                    title="Remove Image"
                  >
                    <AiOutlineClose className="text-gray-500" />
                  </button>
                )}
              </div>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  id="fileUpload"
                  className="hidden"
                  accept="image/*"
                  onChange={handleProfile}
                  ref={fileInputRef}
                />
                <label
                  htmlFor="fileUpload"
                  className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300"
                >
                  Upload thumbnail image
                </label>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <DropZone />
              </div>
            </div>

            <div>
              <label className="block mb-2 text-sm text-gray-900">
                Product Name
              </label>
              <input
                placeholder="Enter product name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                rows={4}
                name="bio"
                value={formData.bio}
                onChange={handleChange}
              ></input>
            </div>

            <div>
              <label className="block mb-2 text-sm text-gray-900">
                Description
              </label>
              <textarea
                placeholder="Enter product description"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                rows={4}
                name="bio"
                value={formData.bio}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>

          {/* Right Section */}
          <div className="space-y-6">
            <div>
              <label
                htmlFor="primarySpecialization"
                className="block mb-2 text-sm text-gray-900"
              >
                Category Name
              </label>
              <select
                id="primarySpecialization"
                name="primary_specialization"
                value={formData.primary_specialization}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
              >
                <option value="" disabled>
                  Select primary specialization
                </option>
                {option1.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2 text-sm text-gray-900">
                Brand Name
              </label>
              <input
                placeholder="Enter product name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                rows={4}
                name="bio"
                value={formData.bio}
                onChange={handleChange}
              ></input>
            </div>
            <div>
              <label
                htmlFor="primarySpecialization"
                className="block mb-2 text-sm text-gray-900"
              >
                Tags
              </label>
              <select
                id="secondarySpecialization"
                name="secondary_specialization"
                value={formData.secondary_specialization}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
              >
                <option value="" disabled>
                  Select secondary specialization
                </option>
                {option2.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <hr className="mt-6 mb-6"></hr>
        <button
          className="px-4 py-2 bg-[#681312] text-white rounded-md"
          onClick={handleSubmit}
        >
          Create a product
        </button>
      </div>
    </div>
  );
};

export default BasicDetails;
