import React, { useState } from "react";

const ProfileDropdown = ({ options, title, placeholder, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <form className="w-full">
      <label htmlFor="options" className="block mb-2 text-sm text-gray-900">
        {title}
      </label>
      <select
        id="options"
        value={value}
        onChange={onChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 "
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </form>
  );
};

export default ProfileDropdown;
