import React from "react";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import DatatableSection from "../../components/datatable/DatatableSection";
import DataTableCus from "../../components/datatable/Datatable";
import MainSection from "../../components/mainSection/MainSection";

const ProductsManagement = () => {
  return (
    <div className="font-semibold">
      <Sidebar />
      <MainSection >
        <Sectionheader title="Products Management">
          <SectionHeaderButton
            redirectPath="/products-management/create-new-product"
            label="Add New Product"
          />
        </Sectionheader>

        <DatatableSection>
          <DataTableCus />
        </DatatableSection>
      </MainSection>
    </div>
  );
};

export default ProductsManagement;
