import React from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import { Tab, Tabs } from "../../components/tabs/Tabs";
import BasicDetails from "./tabs/Basic Details";
import AdvancedInfo from "./tabs/AdvancedInformation";
import ProductVariant from "./tabs/ProductVariants";

const CreateNewProduct = () => {


  return (
    <div className="font-semibold">
      <Sidebar />
      {/* <Sidebar onNavigate={handleNavigation} /> */}

      <MainSection>
        <Sectionheader title="Create New Product"></Sectionheader>

        <Tabs>
          <Tab label="Basic Details"> 
            <BasicDetails/>
          </Tab>
          <Tab label="Advanced Information">
            <AdvancedInfo/>

          </Tab>
          <Tab label="Product Variants">
            <ProductVariant/>

          </Tab>
        </Tabs>
      </MainSection>
    </div>
  );
};

export default CreateNewProduct;
