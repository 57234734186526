import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";

const NotFound = () => {
  return (
    <>
      <Sidebar />
      <MainSection>
        <div className="flex flex-col items-center justify-center min-h-[80vh] bg-white text-gray-800">
          <h1 className="text-2xl lg:text-4xl font-bold">
            404 - Page Not Found
          </h1>
          <p className="mt-4 text-xs sm:text-md lg:text-lg justify-center">
            Sorry, the page you are looking for does not exist.
          </p>
        </div>
      </MainSection>
    </>
  );
};

export default NotFound;
