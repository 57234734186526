import React, { useState, useRef } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import SearchBar from "../../components/searchbar/Search-Bar";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import DatatableSection from "../../components/datatable/DatatableSection";
import DatatableCus from "../../components/datatable/Datatable";

const Patient = () => {
  const option1 = [
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "FR", label: "France" },
    { value: "DE", label: "Germany" },
  ];

  const option2 = [
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "FR", label: "France" },
    { value: "DE", label: "Germany" },
  ];

  const [isChecked, setIsChecked] = useState(false);

  // Handle the change event of the toggle switch
  const handleToggleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [fileName, setFileName] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      //   setFileName(file.name);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Handle image removal
  const handleRemoveImage = () => {
    setFileName("");
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="font-semibold">
      {/* Sidebar */}
      <Sidebar />

      <MainSection>
        <Sectionheader title="Patients">
        </Sectionheader>
        <div className=" bg-white">
          <SearchBar label1="Filter" label2="Export" />
        </div>

        <DatatableSection >
          <DatatableCus />
        </DatatableSection>
      </MainSection>
    </div>
  );
};

export default Patient;
