import { AiOutlineClose } from "react-icons/ai";
import React, { useState } from "react";
import { DatePicker, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const ProductVariant = () => {
  const [directions, setDirections] = useState([""]);
  const [safetyInfo, setSafetyInfo] = useState([""]);
  const [ingredients, setIngredients] = useState([{ name: "", quantity: "" }]);

  // Functions to handle add/remove fields for directions
  const handleAddDirection = () => {
    setDirections([...directions, ""]);
  };

  const handleRemoveDirection = (index) => {
    const newDirections = [...directions];
    newDirections.splice(index, 1);
    setDirections(newDirections);
  };

  // Functions to handle add/remove fields for safety information
  const handleAddSafetyInfo = () => {
    setSafetyInfo([...safetyInfo, ""]);
  };

  const handleRemoveSafetyInfo = (index) => {
    const newSafetyInfo = [...safetyInfo];
    newSafetyInfo.splice(index, 1);
    setSafetyInfo(newSafetyInfo);
  };

  // Functions to handle add/remove fields for ingredients
  const handleAddIngredient = () => {
    setIngredients([...ingredients, { name: "", quantity: "" }]);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
        <div className="w-full space-y-4">
          <label className="block text-sm font-medium mb-2">
            Product Variants
          </label>
          {ingredients.map((ingredient, index) => (
            <div key={index} className="flex items-center gap-2 mb-2">
              <Input
                value={ingredient.name}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].name = e.target.value;
                  setIngredients(newIngredients);
                }}
                className="w-2/3"
                placeholder="Ingredient name goes here"
              />
              <Input
                value={ingredient.quantity}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].quantity = e.target.value;
                  setIngredients(newIngredients);
                }}
                className="w-1/3"
                placeholder="Quantity in gms"
              />
              {ingredients.length > 1 && (
                <AiOutlineClose
                  className="text-black cursor-pointer"
                  onClick={() => handleRemoveIngredient(index)}
                />
              )}
            </div>
          ))}
          <Button
            className="flex items-center hover:!text-[#681312] hover:!border-[#681312]"
            onClick={handleAddIngredient}
          >
            <PlusOutlined /> Add
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductVariant;
