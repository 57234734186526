import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth"; // Import the auth
import doctorReducer from "./doctor";

const store = configureStore({
  reducer: {
    admin: authReducer, // Add the auth slice reducer
    doctor: doctorReducer,
  },
});

export default store;
