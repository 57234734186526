import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'

const Dashboard =() => {
  return (
<>
<Sidebar />
</>
  )
}

export default Dashboard