import { BsFillEyeFill } from "react-icons/bs";
import React from "react";

const ViewButton = ({ onClick }) => {
  return (
    <>
      <button
        onClick={onClick}
        className="px-4 py-2 bg-[#171B29] text-white rounded-md flex items-center"
      >
        <span className="mr-2">View</span>
        <BsFillEyeFill />
      </button>
    </>
  );
};

export default ViewButton;
