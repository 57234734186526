import { AiOutlineClose } from "react-icons/ai";
import React, { useState } from "react";
import { DatePicker, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AdvancedInfo = () => {
  const [expiryDate, setExpiryDate] = useState(null);
  const [directions, setDirections] = useState([""]);
  const [safetyInfo, setSafetyInfo] = useState([""]);
  const [ingredients, setIngredients] = useState([{ name: "", quantity: "" }]);

  // Functions to handle add/remove fields for directions
  const handleAddDirection = () => {
    setDirections([...directions, ""]);
  };

  const handleRemoveDirection = (index) => {
    const newDirections = [...directions];
    newDirections.splice(index, 1);
    setDirections(newDirections);
  };

  // Functions to handle add/remove fields for safety information
  const handleAddSafetyInfo = () => {
    setSafetyInfo([...safetyInfo, ""]);
  };

  const handleRemoveSafetyInfo = (index) => {
    const newSafetyInfo = [...safetyInfo];
    newSafetyInfo.splice(index, 1);
    setSafetyInfo(newSafetyInfo);
  };

  // Functions to handle add/remove fields for ingredients
  const handleAddIngredient = () => {
    setIngredients([...ingredients, { name: "", quantity: "" }]);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
        {/* Left Section */}
        <div className="w-full space-y-4 sm:border-r pr-10">
          <div>
            <label className="block text-sm font-medium mb-2">
              Expiry Date
            </label>
            <DatePicker
              value={expiryDate}
              onChange={(date) => setExpiryDate(date)}
              format="DD/MM/YYYY"
              className="w-full p-2 border rounded-md"
              placeholder="Set expiry date"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              Direction To Use
            </label>
            {directions.map((direction, index) => (
              <div key={index} className="flex items-center gap-2 mb-2">
                <Input
                  value={direction}
                  onChange={(e) => {
                    const newDirections = [...directions];
                    newDirections[index] = e.target.value;
                    setDirections(newDirections);
                  }}
                  className="w-full"
                  placeholder="Add direction to use"
                />
                {directions.length > 1 && (
                  <AiOutlineClose
                    className="text-black cursor-pointer"
                    onClick={() => handleRemoveDirection(index)}
                  />
                )}
              </div>
            ))}
            <Button
              className="flex items-center hover:!text-[#681312] hover:!border-[#681312]"
              onClick={handleAddDirection}
            >
              <PlusOutlined /> Add
            </Button>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              Safety Information
            </label>
            {safetyInfo.map((info, index) => (
              <div key={index} className="flex items-center gap-2 mb-2">
                <Input
                  value={info}
                  onChange={(e) => {
                    const newSafetyInfo = [...safetyInfo];
                    newSafetyInfo[index] = e.target.value;
                    setSafetyInfo(newSafetyInfo);
                  }}
                  className="w-full"
                  placeholder="Add safety information"
                />
                {safetyInfo.length > 1 && (
                  <AiOutlineClose
                    className="text-black cursor-pointer"
                    onClick={() => handleRemoveSafetyInfo(index)}
                  />
                )}
              </div>
            ))}
            <Button
              className="flex items-center hover:!text-[#681312] hover:!border-[#681312]"
              onClick={handleAddSafetyInfo}
            >
              <PlusOutlined /> Add
            </Button>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full space-y-4">
          <label className="block text-sm font-medium mb-2">
            Key Ingredients
          </label>
          {ingredients.map((ingredient, index) => (
            <div key={index} className="flex items-center gap-2 mb-2">
              <Input
                value={ingredient.name}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].name = e.target.value;
                  setIngredients(newIngredients);
                }}
                className="w-2/3"
                placeholder="Ingredient name goes here"
              />
              <Input
                value={ingredient.quantity}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].quantity = e.target.value;
                  setIngredients(newIngredients);
                }}
                className="w-1/3"
                placeholder="Quantity in gms"
              />
              {ingredients.length > 1 && (
                <AiOutlineClose
                  className="text-black cursor-pointer"
                  onClick={() => handleRemoveIngredient(index)}
                />
              )}
            </div>
          ))}
          <Button
            className="flex items-center hover:!text-[#681312] hover:!border-[#681312]"
            onClick={handleAddIngredient}
          >
            <PlusOutlined /> Add
          </Button>
        </div>
      </div>

      <hr className="mt-6 mb-6"></hr>

      <div className="mt-4 w-full">
        <button className="bg-[#681312] text-white py-2 px-4 rounded-md">
          Create a product
        </button>
      </div>
    </>
  );
};

export default AdvancedInfo;
